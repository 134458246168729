<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--models-label-add-edit">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn class="back-btn" :to="{ name: 'modelsLabel' }" round grow icon="arrow-left" />
          <h1 class="page-title" v-html="pageTitle"></h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <SelectExtended v-if="$route.params.id && type_uid.key" id="type_uid" v-model="type_uid"
              label="Type d'étiquette" option-key="uid" option-value="designation"
              api-endpoint="dictionnaire/type/etiquette" required />
            <SelectExtended v-else-if="type_uid" id="type_uid" v-model="type_uid" label="Type d'étiquette"
              option-key="uid" option-value="designation" api-endpoint="dictionnaire/type/etiquette" required />

            <SelectExtended v-if="$route.params.id && zones.key" id="gabarit_uid" v-model="zones"
              label="Gabarit des étiquettes" option-key="id" option-value="designation"
              api-endpoint="dictionnaire/etiquette/gabarit" required @update:modelValue="setZones" />
            <SelectExtended v-else-if="zones" id="gabarit_uid" v-model="zones" label="Gabarit des étiquettes"
              option-key="id" option-value="designation" api-endpoint="dictionnaire/etiquette/gabarit" required
              @update:modelValue="setZones" />

            <Input id="nom" v-model="data.nom" label="Nom du modèle d'étiquette" required />
          </Container>
        </Section>

        <Section v-if="'0' in zones">
          <Container>
            <h2 class="h2">Sélection des zones</h2>

            <template v-for="(n, index) in zones" :key="index">
              <div class="grid margin-bottom" v-if="!['key', 'value'].includes(index)">
                <div class="tmp-col">
                  <SelectExtended v-if="zones[`${index}`].zone" v-model="zones[index].zone.typevaleuretiquette_id"
                    :id="`zones[${index}].zone.typevaleuretiquette_id`" :label="`Zone ${parseInt(index, 10) + 1}`"
                    option-key="uid" option-value="designation" :api-endpoint="typeEndpoint" :required="true"
                    :textError="errors ? errors[`zones[${index}].zone.typevaleuretiquette_id`] : ''"
                    @update:modelValue="setGabarit" />
                  <Input :id="`zones[${index}].zone.label`" v-model="zones[index].zone.label"
                    v-if="zones[index].zone && zones[index].zone.typevaleuretiquette_id.key === 'TEXTELIBRE'"
                    :textError="errors ? errors[`zones[${index}].zone.label`] : ''" label="Votre texte" required />

                </div>
                <div class="tmp-col input-group">
                  <div class="input-group-item">
                    <Btn class="input-group-input" v-if="zones[`${index}`].zone"
                      :color="zones[index].zone.gras ? 'secondary' : 'default'" v-model="zones[index].zone.gras"
                      @click="btnChange(zones[index], 'gras')" icon="bold" />
                  </div>
                  <div class="input-group-item">
                    <Btn class="input-group-input" v-if="zones[`${index}`].zone"
                      :color="zones[index].zone.italique ? 'secondary' : 'default'" v-model="zones[index].zone.italique"
                      @click="btnChange(zones[index], 'italique')" icon="italic" />
                  </div>
                  <div class="input-group-item">
                    <SelectExtended class="input-group-input" v-if="zones[`${index}`].zone" id="id_258"
                      v-model="zones[index].zone.taille_texte" option-key="label" option-value="value" :items="[
                        { value: 'Très petite', label: 10 },
                        { value: 'Petite', label: 14 },
                        { value: 'Normale', label: 16 },
                        { value: 'Grande', label: 24 },
                        { value: 'Très grande', label: 32 },
                        { value: 'Très très grande', label: 48 },
                        { value: 'Enorme', label: 72 },
                      ]" />
                  </div>
                  <div class="input-group-item">
                    <Input class="input-group-input" v-if="zones[`${index}`].zone" v-model="zones[index].zone.couleur"
                      id="couleur" type="color" />
                  </div>
                </div>
              </div>
            </template>
          </Container>
        </Section>

        <Section class="section-modele" v-if="'0' in zones && zones[0].zone_format">
          <Container>
            <h2 class="h2">Aperçu de l'étiquette</h2>
            <div class="modele modele-grid">
              <template v-for="(n, index) in zones" :key="index">
                <div v-if="n.zone_format" :style="{
                  position: 'relative',
                  gridColumn: +n.zone_format.x + '/' +
                    (+n.zone_format.largeur + n.zone_format.x),
                  gridRow: +n.zone_format.y + '/' +
                    (+n.zone_format.hauteur + n.zone_format.y),
                  backgroundColor: '#FFF',
                  border: '1px solid'
                }">
                  <span class="modele-grid__index">{{+index + 1 }}</span>
                  <div class="modele-grid__content">
                    <p :class="{
                      'text-bold': n.zone?.gras,
                      'text-italic': n.zone?.italique
                    }" :style="{
                      fontSize: calculateFontSize(n) + 'px',
                      color: n.zone?.couleur
                    }">
                      {{ helperService.truncateText(n.zone?.typevaleuretiquette_id?.value) }}
                    </p>
                  </div>

                </div>
              </template>
            </div>
          </Container>
        </Section>
      </div><!-- end .view-body -->

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'modelsLabel' }" text="Annuler" />
          <Btn v-if="method === 'put'"
            v-show="helperService.userHasPermission('etiquettemodele_remove') && method === 'put'" hollow
            @click="modalDeleteModel = true" text="Supprimer" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
    <Modal v-if="$route.params.id && modele" title="Supprimer le modèle d'étiquette&nbsp;?" :active="modalDeleteModel"
      :data="modele" @modal-close="modalDeleteModel = false">
      <template v-slot:modal-body="{ data }">
        <p>
          Voulez vous vraiment supprimer le modèle d'étiquette
          <b>{{ data.nom }}</b>?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modalDeleteModel = false" />
        <Btn text="Supprimer" @click="deleteModele()" icon="trash-alt" color="primary" />
      </template>
    </Modal>
  </form>
  <ToolMenu v-if="$route.params.id">
    <MenuItem v-show="helperService.userHasPermission(
      'etiquettemodele_add,etiquettemodele_edit', 'OR')" :item-data="{
        label: 'Consulter',
        iconName: 'visibility',
        route: {
          name: 'modelLabel',
          params: {
            id: $route.params.id
          }
        }
      }" />
    <MenuItem v-show="helperService.userHasPermission('etiquettemodele_remove')" :item-data="{
      label: 'Supprimer',
      iconName: 'trash-alt',
    }" @click="modalDeleteModel = true" />
  </ToolMenu>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolMenu from '@/components/layout/ToolMenu.vue'
import MenuItem from '@/components/layout/MenuItem.vue'

const formats = {
  10: 'Très petite',
  14: 'Petite',
  16: 'Normale',
  24: 'Grande',
  32: 'Très grande',
  48: 'Très très grande',
  72: 'Enorme',
}

export default {
  name: 'ModelsLabelAddEditView',
  components: {
    Modal,
    ToolMenu,
    MenuItem,
    Input,
    SelectExtended,
    Btn,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    /* eslint-disable camelcase */
    const validationSchema = this.yup.object().shape({
      type_uid: this.yup.object().required().nullable(),
      gabarit_uid: this.yup.object().required().nullable(),
      nom: this.yup.string().required(),
      zones: this.yup.array().of(
        this.yup.object().shape({
          zone: this.yup.object({
            typevaleuretiquette_id: this.yup.object().required(),
            label: this.yup.string().nullable(),
            couleur: this.yup.string().nullable(),
            gras: this.yup.boolean().nullable(),
            italique: this.yup.boolean().nullable(),
            taille_texte: this.yup.object().nullable(),
          }).required().nullable(),
        }),
      ).required().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      typeEndpoint: '',
      type_uid: { key: null, value: null },
      type_url: 'dictionnaire/type/etiquette/valeur',
      etiquette: {},
      schema: {},
      id: null,
      zone: { key: null, value: null },
      data: {
        zones: {},
        nom: '',
      },
      gabarit: {},
      method: 'post',
      endpoint: 'etiquette/modele',
      modele: {},
      onSubmit,
      errors,
      isSubmitting,
      modalDeleteModel: false,
      zonesErrors: false,
    }
  },

  computed: {
    zones: {
      get() {
        return this.data.zones
      },
      set(v) {
        this.data.zones = v
      },
    },

    LE() { return this.etiquette?.L },
    HE() { return this.etiquette?.H },
    le() { return this.etiquette?.l },
    he() { return this.etiquette?.h },
    ratio() { return this.etiquette?.L / this.etiquette?.H },
  },

  watch: {
    'data.nom': {
      deep: true,
      handler() {
        this.schema.nom = this.data.nom
        this.formService.setFormValues(this.schema)
      },
    },
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    type_uid() {
      this.schema.type_uid = this.type_uid
      this.formService.setFormValues(this.schema)
      this.typeEndpoint = `dictionnaire/type/etiquette/valeur?filters=type_etiquette.uid:eq(${this.type_uid.key})`
    },
  },

  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.method = 'put'
      this.endpoint = `etiquette/modele/${this.id}`
      this.zones = await this.getModel()
    }
  },

  methods: {
    calculateFontSize(zone) {
      const baseFontSize = zone.zone?.taille_texte?.key || 10
      const widthBasedFontSize = baseFontSize * (zone.zone_format.largeur / this.le) * this.ratio
      const heightBasedFontSize = baseFontSize * (zone.zone_format.hauteur / this.he) * this.ratio
      const length = zone.zone?.typevaleuretiquette_id?.value?.length || 1
      const factor = 0.18 + ((0.5 - 0.18) / (60 - 10)) * (length - 10)
      const textLengthFactor = Math.sqrt(length) * factor
      const finalFontSize = Math.min(widthBasedFontSize, heightBasedFontSize) / textLengthFactor
      return Math.max(10, finalFontSize)
    },
    deleteModele() {
      this.emitter.emit('open-loader')

      this.fetchService.delete(this.endpoint)
        .then(
          (response) => {
            console.log(response)
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le modèle d\'étiquette a bien été supprimé.',
            })
            this.emitter.emit('list-refresh')
            this.emitter.emit('close-loader')
            this.$router.push({ name: 'modelsLabel' })
          },
          (error) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: error.message,
            })
            this.emitter.emit('close-loader')
          },
        )

      this.modalDeleteModel = false
    },
    async getModel() {
      const response = await this.fetchService.get(this.endpoint)
      const data = await response.data
      this.modele = data

      this.schema = this.formService.populateShema(data)

      this.data.nom = this.modele?.nom

      this.schema.type_uid = {
        key: data.type?.uid,
        value: data.type?.designation,
      }

      this.type_uid = {
        key: data.type?.uid,
        value: data.type?.designation,
      }

      this.typeEndpoint = `dictionnaire/type/etiquette/valeur?filters=type_etiquette.uid:eq(${this.type_uid.key})`

      this.zones = {
        key: await data.gabarit?.id,
        value: await data.gabarit?.designation,
      }

      this.zones = await this.setZones()

      this.formService.setFormValues(this.schema)

      return this.zones
    },

    btnChange(zone, field) {
      // eslint-disable-next-line no-param-reassign
      zone.zone[field] = !zone.zone[field]
    },

    setGabarit() {
      if (this.method === 'put') {
        this.schema.gabarit_uid = {
          key: this.gabarit?.id,
          value: this.gabarit?.designation,
        }
      }
      this.formService.setFormValues(this.schema)
    },

    async setZones() {
      this.emitter.emit('open-loader')
      // Récup du gabarit selectionné
      const response = await this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.zones.key}`)
      this.gabarit = await response.data

      if (this.zones) {
        let perso = []
        if (this.$route.params.id) {
          if (this.modele.gabarit.id === this.gabarit.id) {
            const r = await this.fetchService.get(`etiquette/modele/${this.$route.params.id}/zone`)
            perso = await r.data
          }
        }

        // si des zones personnalisées sont stockées pour le modele d'etiquette en cours
        if (perso.length > 0) {
          // const gz = perso.map((item) => item.gabarit_zone)
          const { L, H } = this.getRealSizeLabel(this.gabarit)
          // eslint-disable-next-line prefer-spread
          const l = 100// Math.max(...gz.map((o) => parseInt(o.x, 10) + parseInt(o.largeur, 10)))
          const h = 100// Math.max(...gz.map((o) => parseInt(o.y, 10) + parseInt(o.hauteur, 10)))
          this.etiquette = {
            L, H, l, h,
          }
          this.zones = { key: this.gabarit.id, value: this.gabarit.designation }
          perso.forEach((item, index) => {
            this.zones[index] = {}
            this.zones[index].gabarit = Object.fromEntries(
              Object.entries(this.gabarit).filter(([key, value]) => key !== 'zones' && value),
            )
            this.zones[index].zone_format = item.gabarit_zone
            if (+this.zones[index].zone_format.x === 0) {
              this.zones[index].zone_format.x = 1
            } else {
              this.zones[index].zone_format.x = +this.zones[index].zone_format.x + 1
            }
            if (+this.zones[index].zone_format.y === 0) {
              this.zones[index].zone_format.y = 1
            } else {
              this.zones[index].zone_format.y = +this.zones[index].zone_format.y + 1
            }
            this.zones[index].zone_format.hauteur = +this.zones[index].zone_format.hauteur
            this.zones[index].zone_format.largeur = +this.zones[index].zone_format.largeur
            this.zones[index].zone = {
              couleur: item.couleur,
              gras: item.gras,
              italique: item.italique,
              taille_texte: {
                value: formats[item.taille_texte],
                key: item.taille_texte,
              },
              typevaleuretiquette_id:
              {
                value: item.type_valeur_etiquette.designation,
                key: item.type_valeur_etiquette.uid,
              },
              label: item.label,
            }
          })
          this.zones = this.sortByOrder(this.zones)
          let kz = Object.keys(this.zones)
          kz = kz.filter((item) => !Number.isNaN(+item))
          this.schema.zones = kz.map((item) => {
            const data = {
              zone: {
                typevaleuretiquette_id: {
                  value: this.zones[+item].zone.typevaleuretiquette_id.value,
                  key: this.zones[+item].zone.typevaleuretiquette_id.key,
                },
              },
            }
            return data
          })
          this.schema.gabarit_uid = this.zones
        } else {
          const rz = await this.fetchService.get(`dictionnaire/etiquette/gabarit/${this.zones.key}/zone`)
          this.zones = {}
          const zones = await rz.data
          const { L, H } = this.getRealSizeLabel(this.gabarit)
          // eslint-disable-next-line prefer-spread
          const l = 100// Math.max(...zones.map((o) => parseInt(o.x, 10) + parseInt(o.largeur, 10)))
          const h = 100// Math.max(...zones.map((o) => parseInt(o.y, 10) + parseInt(o.hauteur, 10)))
          this.etiquette = {
            L, H, l, h,
          }
          this.zones.key = this.gabarit.id
          this.zones.value = this.gabarit.designation
          this.schema.gabarit_uid = this.zones
          zones.forEach((zone, index) => {
            this.zones[index] = {}
            this.zones[index].gabarit = Object.fromEntries(
              Object.entries(this.gabarit).filter(([key, value]) => key !== 'zones' && value),
            )
            this.zones[index].zone_format = zone
            if (+this.zones[index].zone_format.x === 0) {
              this.zones[index].zone_format.x = 1
            } else {
              this.zones[index].zone_format.x = +this.zones[index].zone_format.x + 1
            }
            if (+this.zones[index].zone_format.y === 0) {
              this.zones[index].zone_format.y = 1
            } else {
              this.zones[index].zone_format.y = +this.zones[index].zone_format.y + 1
            }
            this.zones[index].zone_format.hauteur = +this.zones[index].zone_format.hauteur
            this.zones[index].zone_format.largeur = +this.zones[index].zone_format.largeur

            this.zones[index].zone = {
              couleur: '#000000',
              gras: false,
              italique: false,
              taille_texte: { value: 'normal', key: 14 },
              typevaleuretiquette_id: { value: null, key: null },
              label: null,
            }
          })
          this.zones = this.sortByOrder(this.zones)
          console.log(this.zones)
          let kz = Object.keys(this.zones)
          kz = kz.filter((item) => !Number.isNaN(+item))
          this.schema.zones = kz.map((item) => {
            const data = {
              zone: {
                typevaleuretiquette_id: {
                  value: this.zones[+item].zone.typevaleuretiquette_id.value,
                  key: this.zones[+item].zone.typevaleuretiquette_id.key,
                },
              },
            }
            return data
          })
          this.schema.gabarit_uid = this.zones
        }
        this.emitter.emit('close-loader')
      }

      if (this.method === 'post') {
        this.formService.setFormValues(this.schema)
      }

      return this.zones
    },

    handleValues(values) {
      // prepare the data for API
      this.emitter.emit('open-loader')

      const params = values

      params.gabarit_uid = this.gabarit?.uid
      params.type_uid = values.type_uid?.key

      const zonesParams = this.getZonesToStore(this.zones)

      if (Object.keys(this.errors).length) {
        // this.formService.handleErrors(true)
        this.zonesErrors = true
      }

      if (!this.zonesErrors) {
        this.fetchService[this.method](this.endpoint, params).then(
          (response) => {
            const { id } = response.data

            this.fetchService.get(`etiquette/modele/${id}/zone`).then((rzd) => {
              const zd = rzd.data
              if (zd.length > 0) {
                zd.forEach((z) => {
                  this.fetchService.delete(`etiquette/modele/${id}/zone/${z.id}`)
                  console.log(z.id)
                })
              }
            }).then(() => {
              zonesParams.forEach((zone) => {
                if (zone.type_valeuretiquette_uid !== 'TEXTELIBRE') {
                  /* eslint-disable no-param-reassign */
                  zone.label = null
                }
                const zendpoint = `etiquette/modele/${id}/zone`

                this.fetchService.post(zendpoint, zone).then(
                  (r) => {
                    console.log(r.data)
                  },
                  (e) => {
                    this.formService.handleApiError(e, 'Impossible de créer la zone personnalisée')
                    this.emitter.emit('close-loader')
                  },
                )
              })
            })
            this.emitter.emit('list-refresh')
          },

        )
        this.emitter.emit('close-loader')
        this.$router.push({ name: 'modelsLabel' })
      } else {
        this.emitter.emit('close-loader')
        this.zonesErrors = false
      }
    },

    getZonesToStore(datas) {
      const params = []
      Object.keys(datas).forEach((data) => {
        if (!Number.isNaN(+data)) {
          const param = {}
          param.gras = datas[data].zone?.gras
          param.italique = datas[data].zone?.italique
          param.taille_texte = datas[data].zone?.taille_texte?.key
          param.couleur = datas[data].zone?.couleur
          param.gabarit_zone_id = datas[data].zone_format?.id
          param.type_valeuretiquette_uid = datas[data].zone?.typevaleuretiquette_id?.key
          param.label = datas[data].zone?.label
          if (typeof (param.type_valeuretiquette_uid) === 'undefined'
            || param.type_valeuretiquette_uid === null) {
            this.errors[`zones[${data}].zone.typevaleuretiquette_id`] = 'Le champs est requis.'
            this.formService.setFieldError(`zones[${data}].zone.typevaleuretiquette_id`, 'Le champs est obligatoire')
          }
          if ((param.label === null || param.label === '' || typeof param.label === 'undefined') && param.type_valeuretiquette_uid === 'TEXTELIBRE') {
            this.errors[`zones[${data}].zone.label`] = 'Le champs est requis.'
            this.formService.setFieldError(`zones[${data}].zone.label`, 'Le champs est obligatoire')
          }
          params.push(param)
        }
      })

      return params
    },

    sortByOrder(element) {
      const data = {}
      const keys = Object.keys(element).sort(
        (a, b) => element[a].zone_format?.id - element[b].zone_format?.id,
      )
      keys.forEach((k, i) => {
        if (Number.isNaN(+k)) {
          data[k] = element[k]
        } else {
          data[i] = element[k]
        }
      })
      return data
    },

    getRealSizeLabel(gabarit) {
      let L = (Number(+gabarit.page_largeur) - Number(+gabarit.marge_gauche)
        - Number(+gabarit.marge_droite)
        - ((Number(+gabarit.nb_etiquettes_x) - 1) * Number(+gabarit.marge_etiquette_x)))
        / Number(+gabarit.nb_etiquettes_x)

      let H = (Number(+gabarit.page_hauteur) - Number(+gabarit.marge_haut)
        - Number(+gabarit.marge_bas)
        - ((Number(+gabarit.nb_etiquettes_y) - 1) * Number(+gabarit.marge_etiquette_y)))
        / Number(+gabarit.nb_etiquettes_y)

      L = Number(L.toFixed(2))
      H = Number(H.toFixed(2))

      return { L, H }
    },
  },
}
</script>
<style lang="scss" scoped>
// .grid:not(:first-child){
//   margin-top: 30px;
// }
.input-group {
  align-self: end;
  // width: 300px;
  // height: 40px;
  display: grid;
  grid-template-columns: $input-height-base $input-height-base 1fr $input-height-base;
  border-radius: $border-radius-base;
}

.input-group-item {
  display: flex;
  align-content: center;
  justify-content: center;

  &:nth-child(-n+2) {
    // Two first elements
    overflow: hidden; // Prevents shadows on buttons
    border: 1px solid $color-gray-light;
    border-right: none;

    :deep(.btn) {
      height: calc(#{$input-height-base} - 2px);
    }
  }

  &:first-child,
  &:first-child .input-group-input {
    border-radius: $border-radius-base 0 0 $border-radius-base;
  }
}

.input-group-input {
  width: 100%;
  height: 100%;
  border-radius: initial;

  :deep(.multiselect__tags) {
    border-radius: unset;
  }

  :deep(input[type=color]) {
    width: 100%;
    height: $input-height-base;
    border-radius: 0 $border-radius-base $border-radius-base 0;
    border-left: none;
  }
}

.modele-grid {
  box-shadow: inset 0 0 0 1px;
  font-family: sans-serif;
  background-color: white;
  margin: 0 auto;
  width: 95%;
  aspect-ratio: v-bind(ratio);
  display: grid;
  grid-template-columns: repeat(v-bind(le), 1fr);
  grid-template-rows: repeat(v-bind(he), 1fr);
  grid-gap: 1px;
  padding: 1px;
}

.modele-grid__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  >p {
    text-align: center;
    text-transform: uppercase;
    padding: 0 5px;
    margin: 0;
  }
}

.modele-grid__index {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  font-size: $font-size-small;
  font-weight: bold;
  color: white;
  @include size(2rem);
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}
</style>
