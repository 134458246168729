<template>
  <div class="view view--product">
    <div class="view-header">
      <div class="view-header__footer">
        <Btn class="back-btn" :to="{ name: 'modelsLabel' }" round grow icon="arrow-left" />
        <h1 class="page-title" v-html="`Modèle d'étiquette : ${data.modele?.nom}`"></h1>
      </div>
    </div>

    <div class="view-body">

      <Section>
        <Container>
          <Section v-if="data.modele">

            <div class="form grid">
              <div class="tmp-col">
                <KeyValue label="Nom du modèle d'étiquette" :value="data.modele.nom" />
              </div>
              <div class="tmp-col">
                <KeyValue label="Type d'étiquette" :value="data.modele.type.designation" />
              </div>
              <div class="tmp-col">
                <KeyValue label="Gabarit d'étiquette" :value="data.modele.gabarit.designation" />
              </div>
            </div>

          </Section>

          <Section class="section-modele" v-if="data.zones">
            <div class="modele modele-grid">
              <template v-for="(n, index) in data.zones" :key="index">
                <div v-if="n.zone_format" :style="{
                  position: 'relative',
                  gridColumn: +n.zone_format.x + '/' +
                    (n.zone_format.largeur + n.zone_format.x),
                  gridRow: +n.zone_format.y + '/' +
                    (n.zone_format.hauteur + n.zone_format.y),
                  backgroundColor: '#FFF',
                  border: '1px solid'
                }">
                  <span class="modele-grid__index">{{+index + 1 }}</span>
                  <div class="modele-grid__content">
                    <p :class="{
                      'text-bold': n.contenu?.gras,
                      'text-italic': n.contenu?.italique
                    }" :style="{
                      fontSize: calculateFontSize(n) + 'px',
                      color: n.contenu?.couleur
                    }">
                      {{ helperService.truncateText(n.contenu?.type_valeur_etiquette) }}
                    </p>
                  </div>

                </div>
              </template>
            </div>
          </Section>

        </Container>
      </Section>

    </div>

    <div class="view-footer">
      <div class="view-actions">
        <Btn v-if="helperService.userHasPermission('etiquettemodele_edit')"
          :to="{ name: 'modelLabelEdit', params: { id: id } }" class="action-btn" text="Modifier le modèle d'étiquette"
          icon="create" color="primary" />
      </div>
    </div>
  </div>

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'ModelLabelView',

  components: {
    Btn,
    Section,
    Container,
    KeyValue,
  },

  data() {
    return {
      data: [],
      id: null,

    }
  },
  computed: {
    LE() { return this.data?.etiquette?.L },
    HE() { return this.data?.etiquette?.H },
    le() { return this.data?.etiquette?.l },
    he() { return this.data?.etiquette?.h },
    ratio() { return this.data?.etiquette?.L / this.data?.etiquette?.H },
  },

  async created() {
    this.id = Number(this.$route.params.id)
    this.getData()
  },

  unmounted() {
    this.data = []
  },

  methods: {
    calculateFontSize(zone) {
      const baseFontSize = zone.contenu?.taille_texte || 10
      const widthBasedFontSize = baseFontSize * (zone.zone_format.largeur / this.le) * this.ratio
      const heightBasedFontSize = baseFontSize * (zone.zone_format.hauteur / this.he) * this.ratio
      const length = zone.contenu?.type_valeur_etiquette.length || 1
      const factor = 0.18 + ((0.5 - 0.18) / (60 - 10)) * (length - 10)
      const textLengthFactor = Math.sqrt(length) * factor
      const finalFontSize = Math.min(widthBasedFontSize, heightBasedFontSize) / textLengthFactor

      return Math.max(10, finalFontSize)
    },
    getData() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`etiquette/modele/${this.id}`).then((mr) => {
        this.data.modele = mr.data
        this.data.zones = []
        this.fetchService.get(`etiquette/modele/${this.id}/zone`).then((zr) => {
          const zones = zr.data
          // const gz = zones.map((item) => item.gabarit_zone)
          const { L, H } = this.getRealSizeLabel(this.data.modele.gabarit)
          // eslint-disable-next-line prefer-spread
          const l = 100// Math.max(...gz.map((o) => parseInt(o.x, 10) + parseInt(o.largeur, 10)))
          const h = 100// Math.max(...gz.map((o) => parseInt(o.y, 10) + parseInt(o.hauteur, 10)))
          this.data.etiquette = {
            L, H, l, h,
          }
          zones.forEach((item, index) => {
            this.data.zones[index] = {}
            this.data.zones[index].gabarit = Object.fromEntries(
              Object.entries(this.data.modele.gabarit).filter(([key, value]) => key !== 'zones' && value),
            )
            this.data.zones[index].zone_format = item.gabarit_zone
            if (+this.data.zones[index].zone_format.x === 0) {
              this.data.zones[index].zone_format.x = 1
            } else {
              this.data.zones[index].zone_format.x = +this.data.zones[index].zone_format.x + 1
            }
            if (+this.data.zones[index].zone_format.y === 0) {
              this.data.zones[index].zone_format.y = 1
            } else {
              this.data.zones[index].zone_format.y = +this.data.zones[index].zone_format.y + 1
            }
            this.data.zones[index].zone_format.hauteur = +this.data.zones[index].zone_format.hauteur
            this.data.zones[index].zone_format.largeur = +this.data.zones[index].zone_format.largeur
            this.data.zones[index].contenu = {
              id: item.id,
              couleur: item.couleur,
              gras: item.gras,
              italique: item.italique,
              taille_texte: item.taille_texte,
              type_valeur_etiquette: item.type_valeur_etiquette?.designation,
            }
          })
          this.data.zones = this.sortByOrder(this.data.zones)
        })
      })
      this.emitter.emit('close-loader')
    },

    sortByOrder(element) {
      const data = {}
      const keys = Object.keys(element).sort(
        (a, b) => element[a].zone_format.id - element[b].zone_format.id,
      )
      keys.forEach((k, i) => {
        if (Number.isNaN(+k)) {
          data[k] = element[k]
        } else {
          data[i] = element[k]
        }
      })
      return data
    },
    getRealSizeLabel(gabarit) {
      let L = (Number(+gabarit.page_largeur) - Number(+gabarit.marge_gauche)
        - Number(+gabarit.marge_droite)
        - ((Number(+gabarit.nb_etiquettes_x) - 1) * Number(+gabarit.marge_etiquette_x)))
        / Number(+gabarit.nb_etiquettes_x)

      let H = (Number(+gabarit.page_hauteur) - Number(+gabarit.marge_haut)
        - Number(+gabarit.marge_bas)
        - ((Number(+gabarit.nb_etiquettes_y) - 1) * Number(+gabarit.marge_etiquette_y)))
        / Number(+gabarit.nb_etiquettes_y)

      L = Number(L.toFixed(2))
      H = Number(H.toFixed(2))

      return { L, H }
    },
  },

}
</script>
<style lang="scss" scoped>
.modele-grid {
  box-shadow: inset 0 0 0 1px;
  font-family: sans-serif;
  background-color: white;
  margin: 0 auto;
  width: 95%;
  aspect-ratio: v-bind(ratio);
  display: grid;
  grid-template-columns: repeat(v-bind(le), 1fr);
  grid-template-rows: repeat(v-bind(he), 1fr);
  grid-gap: 1px;
  padding: 1px;
}

.modele-grid__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  >p {
    text-align: center;
    text-transform: uppercase;
    padding: 0 5px;
    margin: 0;
  }
}

.modele-grid__index {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  font-size: $font-size-small;
  font-weight: bold;
  color: white;
  @include size(2rem);
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}
</style>
